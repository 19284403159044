import React from "react";
import { Box, Button, Chip } from "@mui/material";
import { Close } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function UploadButton(props) {
  const { handleFileUpload, selectedFile, setSelectedFile, disabled, fileTypes } = props;

  const FileChip = ({ file, onDelete }) => (
    <Chip
      label={file.name}
      onDelete={() => {
        setSelectedFile(null);
      }}
      deleteIcon={<Close style={{ fontSize: "8px" }} />}
      style={{ marginRight: 8, marginBottom: 8 }}
    />
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "#3c3c3e",
        borderRadius: "5px",
      }}
    >
      <Box
        color={"white"}
        display={"flex"}
        alignItems={"center"}
        gridGap={"15px"}
      >
        <>
          {selectedFile ? (
            <Box>
              <FileChip file={selectedFile} onDelete={handleFileUpload} />
            </Box>
          ) : (
            <>
              <CloudUploadIcon style={{ fontSize: "35px" }} />
              <Box>
                Drag and drop file here
                <div
                  style={{
                    fontSize: "smaller",
                    marginTop: "5px",
                    color: "#aeacac",
                  }}
                >
                  Limit 200MB per file - {fileTypes}
                </div>
              </Box>
            </>
          )}
        </>
      </Box>
      <label htmlFor="file-upload">
        <input
          id="file-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileUpload}
          accept={fileTypes}
          disabled={disabled}
        />
        <Button
          variant="contained"
          component="span"
          style={{
            backgroundColor: "black",
            color: "white",
            borderRadius: "10px",
          }}
        >
          Browse files
        </Button>
      </label>
    </div>
  );
}
