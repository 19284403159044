import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { TextField } from "@mui/material";

const TextArea = forwardRef((props, ref) => {
  const {
    value,
    onChange,
    placeholder,
    autoFocus,
    name,
    type,
    error,
    disabled,
  } = props;
  const textAreaRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      textAreaRef.current?.focus();
    },
  }));

  return (
    <TextField
      ref={textAreaRef}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      autoFocus={autoFocus}
      name={name}
      type={type}
      multiline
      minRows={4}
      variant="outlined"
      fullWidth
      error={error}
      helperText={error && "Please enter a valid value"}
      disabled={disabled}
    />
  );
});

export default TextArea;
