import React from "react";
import { Box, Button } from "@mui/material";

const Download = (props) => {
  return (
    <Box>
      <Button
        className="downloadButton"
        style={{
          color: "white",
          borderColor: "white",
          boxShadow: "0 0 12px white",
        }}
        variant="outlined"
        onClick={props.downloadFile}
      >
        Download
      </Button>
    </Box>
  );
};

export default Download;
