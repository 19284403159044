import axios from "axios";

// Create a new instance of Axios
console.log(process.env.BASE_URL);
const baseURL = `https://genisdlc-nonprod.mobility.corpinter.net/genitester-backend/`;
const api = axios.create({
  baseURL: baseURL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
    // Uncomment below header's for local development
    // "Access-Control-Allow-Origin": "*",
    // "Referer:" : "https://genisdlc-nonprod.mobility.corpinter.net/",
    "Token" : ""
    // Add any additional headers you need
  },
});

api.interceptors.request.use(
  config => {
    console.log("reading session object");
    var sessionObject = sessionStorage.getItem("tokenObject");
    var token = sessionObject != null ? JSON.parse(sessionObject).accessToken : "";
    config.headers['Token'] = token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
  response => {
    console.log("Reached interceptor for the success responses")
    return response
  },
  error => {
    // Handle 403 error
    if (error.response && error.response.status === 403) {
      console.error('Access forbidden: ', error.response.data);
      // You can perform specific actions here like redirecting to a login page or showing an alert
      // For example, redirect to login page:
      sessionStorage.clear();
      window.location.href = "https://genisdlc-nonprod.mobility.corpinter.net/genitester-backend/auth"
    }
    return Promise.reject(error);
  }
);

export default api;
