import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import React, { useEffect } from "react";
import SidebarNav from "../components/Sidebar/Sidebar";
import FileUpload from "../components/FileUpload/FileUpload";
import TextArea from "../components/TextUpload/TextUpload";
import api from "../utils/getAxiosInstance";
import "../App.css";
import Download from "../components/Download/Download";
import { QueryBuilder } from "@mui/icons-material";
import { formatTime } from "../utils/formatTime";
export default function TestCaseGenerator() {
  const [generateOption, setGenerateOption] = React.useState(
    "Business Test Cases"
  );
  const [methodType, setMethodType] = React.useState("file");
  const [testCaseType, setTestCaseType] = React.useState("Positive");
  const [formatType, setFormatType] = React.useState("General");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [textInput, setTextInput] = React.useState("");
  const textAreaRef = React.useRef(null);
  const [errors, setErrors] = React.useState({});
  const [apiRespState, setApiRespState] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputsDisabled, setInputsDisabled] = React.useState(false);
  const [fileTypes, setFileTypes] = React.useState();

  const sessionObject = sessionStorage.getItem("tokenObject");

  React.useEffect(() => {
    if (!sessionObject) {
      window.location.href = "https://genisdlc-nonprod.mobility.corpinter.net/genitester-backend/auth/";
    }
  }, [sessionObject]);


  useEffect(() => {
    let allowedTypes = "";
    switch (generateOption) {
      case "Business Test Cases":
        allowedTypes = ".txt, .docx, .xml, .csv, .xlsx";
        break;
      case "Field Validation Test Cases":
        allowedTypes = ".png, .jpeg, .jpg";
        break;
      case "Cucumber Scripts":
        allowedTypes = ".txt, .docx, .xml, .csv, .xlsx";
        break;
      default:
        allowedTypes = ".txt, .docx, .xml, .csv, .xlsx";
        break;
    }
    setFileTypes(allowedTypes);
  }, [generateOption]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const setters = {
      generateOption: setGenerateOption,
      methodType: setMethodType,
      testCaseType: setTestCaseType,
      formatType: setFormatType,
    };
    setters[name](value);
    setApiRespState("");
    if (name === "generateOption" || name === "methodType") {
      setSelectedFile(null);
    }
    setTextInput("");
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setApiRespState("");
  };

  const validate = () => {
    const newErrors = {};
    if (!selectedFile && methodType.includes("file")) {
      newErrors.file = "File upload is required.";
    }
    if (textInput.length <= 0 && methodType.includes("text")) {
      newErrors.text = "Enter a valid text.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async () => {
    if (validate()) {
      setIsLoading(true);
      setInputsDisabled(true);
      if (methodType.includes("text")) {
        let obj = {
          user_story: textInput,
          test_case_type: generateOption,
          format_name: formatType,
          case_type: testCaseType,
        };
        try {
          const response = await api.post(
            `generate-test-cases/`,
            JSON.stringify(obj)
          );
          if (response.status === 200 || response.status === 202) {
            setApiRespState(response.data);
          }
        } catch (error) {
          setApiRespState("Something went wrong. Please try again later.");
        }
      } else {
        const data = new FormData();
        data.append("user_story", selectedFile);
        data.append("test_case_type", generateOption);
        data.append("format_name", formatType);
        data.append("case_type", testCaseType);
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        try {
          const response = await api.post(`generate-test-cases/`, data, config);
          if (response.status === 200 || response.status === 202) {
            setApiRespState(response.data);
          }
        } catch (error) {
          setApiRespState("Something went wrong. Please try again later.");
        }
      }
      setIsLoading(false);
      setInputsDisabled(false);
    }
  };
  const downloadFile = () => {
    const filename = "test_cases.txt";
    const element = document.createElement("a");
    const file = new Blob(
      [JSON.stringify(apiRespState.test_cases).replaceAll(/\\n/g, "\n")],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  };
  return (
    sessionObject ? (
      <Box
        style={{
          overflowY: "scroll"
        }}
        bgcolor={"black"}
        display={"flex"}
        width={"100%"}
        height={"100%"}
      >
        <SidebarNav />
        <Box width={"100%"} p={8}>
          <h1 className="testCaseHero">Test Case Designer</h1>
          {/* <Typography className="testCaseDesc">
          Select the process you want to go with
        </Typography> */}
          <Box mt={5}>
            <p
              className="testCaseDesc"
              style={{
                textAlign: "left",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              Choose option that you want to generate:
            </p>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              fullWidth
              style={{ marginBottom: "20px" }}
            >
              <Select
                name="generateOption"
                value={generateOption}
                onChange={handleChange}
                disabled={inputsDisabled}
                style={{ color: "white" }}
              >
                <MenuItem value={"Business Test Cases"}>
                  Business Test Cases
                </MenuItem>
                <MenuItem value={"Field Validation Test Cases"}>
                  Field Validation Test Cases
                </MenuItem>
                <MenuItem value={"Cucumber Scripts"}>Cucumber Scripts</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <p
              className="testCaseDesc"
              style={{
                textAlign: "left",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              Select method:
            </p>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              fullWidth
              style={{ marginBottom: "20px" }}
            >
              <Select
                name="methodType"
                value={methodType}
                onChange={handleChange}
                disabled={inputsDisabled}
                style={{ color: "white" }}
              >
                <MenuItem value={"text"}>Text Uploader</MenuItem>
                <MenuItem value={"file"}>File Uploader</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={"20px"}>
            <h1 className="fileUploadHero">
              {methodType.includes("text") ? "Text Upload" : "File Upload"}
            </h1>
            {methodType.includes("text") ? (
              <>
                <p
                  className="testCaseDesc"
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Enter User Story
                </p>
                <FormControl
                  fullWidth
                  style={{ marginBottom: "20px" }}
                  error={errors.text !== undefined}
                >
                  <TextArea
                    ref={textAreaRef}
                    value={textInput}
                    onChange={(val) => {
                      setTextInput(val);
                      setApiRespState("");
                      setSelectedFile(null);
                    }}
                    disabled={inputsDisabled}
                  />
                  {errors.text && (
                    <FormHelperText style={{ marginTop: 10 }}>
                      {errors.text}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            ) : (
              <>
                <p
                  className="testCaseDesc"
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Upload User Story
                </p>
                <FormControl
                  fullWidth
                  style={{ marginBottom: "20px" }}
                  error={errors.file !== undefined}
                >
                  <FileUpload
                    handleFileUpload={handleFileUpload}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    disabled={inputsDisabled}
                    fileTypes={fileTypes}
                  />
                  {errors.file && (
                    <FormHelperText style={{ marginTop: 10 }}>
                      {errors.file}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            )}
          </Box>
          <Box>
            <p
              className="testCaseDesc"
              style={{
                textAlign: "left",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              Enter format which you want your test case to be
            </p>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              fullWidth
              style={{ marginBottom: "20px" }}
            >
              <Select
                name="formatType"
                value={formatType}
                onChange={handleChange}
                disabled={inputsDisabled}
                style={{ color: "white" }}
              >
                <MenuItem value={"General"}>General</MenuItem>
                <MenuItem value={"PEGA"}>PEGA</MenuItem>
                <MenuItem value={"SCOD"}>SCOD</MenuItem>
                <MenuItem value={"SAP"}>SAP</MenuItem>
                <MenuItem value={"MOBRDR"}>MOBRDR</MenuItem>
                <MenuItem value={"None"}>None</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <p
              className="testCaseDesc"
              style={{
                textAlign: "left",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              Select the test case type:
            </p>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              fullWidth
              style={{ marginBottom: "20px" }}
            >
              <Select
                name="testCaseType"
                value={testCaseType}
                onChange={handleChange}
                disabled={inputsDisabled}
                style={{ color: "white" }}
              >
                <MenuItem value={"Positive"}>Positive</MenuItem>
                <MenuItem value={"Negative"}>Negative</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
              border: "1px solid lightgrey",
              marginTop: "15px",
              boxShadow: "0 0 12px white",
            }}
            onClick={handleSubmit}
            disabled={inputsDisabled}
          >
            {isLoading ? "Generating..." : "Generate"}
          </Button>
          {apiRespState && (
            <Box>
              <h1 className="testCaseHero">Generated test cases</h1>
              <Box
                bgcolor={"#3c3c3e"}
                width={"100%"}
                p={2}
                borderRadius={5}
                style={{ color: "white" }}
                fontSize={16}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  padding={"0 20px"}
                >
                  <Download downloadFile={downloadFile} />
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <QueryBuilder />{" "}
                    <span style={{ display: "inline-block", paddingLeft: "5px" }}>
                      Time
                    </span>
                    <span style={{ display: "inline-block", padding: "0 5px" }}>
                      :
                    </span>
                    {formatTime(apiRespState.time_taken)}{" "}
                  </p>
                </Box>
                <div className="prettyprint">
                  <pre>
                    {JSON.stringify(apiRespState.test_cases).replaceAll(
                      /\\n/g,
                      "\n"
                    )}
                  </pre>
                </div>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    ) : <></>
  );
}
