import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import TestCaseGenerator from "./pages/testCaseGenerator";
import AutomationScriptGenerator from "./pages/automationScriptGenerator";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test-case-generator" element={<TestCaseGenerator />} />
        <Route path="/automation-script-generator" element={<AutomationScriptGenerator />} />
      </Routes>
    </Router>
  );
}

export default App;
