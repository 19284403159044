import React from 'react';
  
  const FormatTime = () =>  {
	return (
	  <div>
	  </div>
	);
  }
  
  export default FormatTime;
  export const formatTime = (timeInSeconds) => {
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  return `${minutes} minutes, ${seconds} seconds`;
};
