import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Menu as MenuIcon, DoubleArrow } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
function SidebarNav() {
  const { collapseSidebar, collapsed } = useProSidebar();
  const location = useLocation();
  const navigate = useNavigate();
  const sideBarMenuArr = [
    {
      path: "/",
      name: "Main Page",
    },
    {
      path: "/test-case-generator",
      name: "User Story to Test Cases",
    },
    {
      path: "/automation-script-generator",
      name: "Test Cases to Web Automation Scripts",
    },
  ];
  return (
    <>
      <Sidebar width="350px" collapsedWidth="0px">
        <Menu>
          <Menu iconShape="circle">
            <MenuItem
              className="sidebar-header"
              suffix={
                <DoubleArrow
                  style={{
                    transform: "rotate(180deg)",
                    marginBottom: "5px",
                    color: "white",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => collapseSidebar(true)}
                />
              }
            >
              <div
                style={{
                  padding: "9px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: 15,
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                Genitester
              </div>
            </MenuItem>
          </Menu>
          {sideBarMenuArr.map((item) => (
            <MenuItem
              style={{
                backgroundColor:
                  location.pathname === item.path ? "#4e4e50" : "",
                fontSize: "15px",
                color: "white",
              }}
              onClick={() => {
                navigate(item.path);
              }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Menu>
      </Sidebar>
      {collapsed && (
        <Box color={"white"} bgcolor={"black"} p={2}>
          <MenuIcon
            onClick={() => {
              collapseSidebar(false);
            }}
            style={{ cursor: "pointer", fontSize: "30px" }}
          />
        </Box>
      )}
    </>
  );
}

export default SidebarNav;
