import React from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import SidebarNav from "../components/Sidebar/Sidebar";
import FileUpload from "../components/FileUpload/FileUpload";
import api from "../utils/getAxiosInstance";
import TextArea from "../components/TextUpload/TextUpload";
import Download from "../components/Download/Download";
import "../App.css";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { formatTime } from "../utils/formatTime";
import { QueryBuilder } from "@mui/icons-material";

export default function AutomationScriptGenerator() {
  const [frameworkType, setFrameworkType] = React.useState("OSAM Framework");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [apiRespState, setApiRespState] = React.useState();
  const [textInput, setTextInput] = React.useState("");
  const textAreaRef = React.useRef(null);
  const [methodType, setMethodType] = React.useState("file");
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputsDisabled, setInputsDisabled] = React.useState(false);
  const sessionObject = sessionStorage.getItem("tokenObject");

  React.useEffect(() => {
    if (!sessionObject) {
      window.location.href = "https://genisdlc-nonprod.mobility.corpinter.net/genitester-backend/auth/";
    }
  }, [sessionObject]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const setters = {
      frameworkType: setFrameworkType,
      methodType: setMethodType,
    };
    setters[name](value);
    setApiRespState("");
    setSelectedFile(null);
    setTextInput("");
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setApiRespState("");
  };
  const validate = () => {
    const newErrors = {};
    if (!selectedFile && methodType.includes("file")) {
      newErrors.file = "File upload is required.";
    }
    if (textInput.length <= 0 && methodType.includes("text")) {
      newErrors.text = "Enter a valid text.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async () => {
    if (validate()) {
      setIsLoading(true);
      setInputsDisabled(true);
      if (methodType.includes("text")) {
        let obj = {
          test_case: textInput,
          framework: frameworkType,
        };
        try {
          const response = await api.post(
            "generate-automation-scripts/",
            JSON.stringify(obj)
          );
          if (response.status === 200 || response.status === 202) {
            setApiRespState(response.data);
          }
        } catch (error) {
          setApiRespState("Something went wrong. Please try again later.");
        }
      } else {
        const data = new FormData();
        data.append("framework", frameworkType);
        data.append("test_case", selectedFile);
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        try {
          const response = await api.post(
            `generate-automation-scripts/`,
            data,
            config
          );
          if (response.status === 200 || response.status === 202) {
            setApiRespState(response.data);
          }
        } catch (error) {
          setApiRespState("Something went wrong. Please try again later.");
        }
      }
      setIsLoading(false);
      setInputsDisabled(false);
    }
  };

  const downloadFile = () => {
    const filename = "automation_script";
    const zip = new JSZip();
    zip.file("method.ts", apiRespState.method);
    zip.file("test_case.feature", apiRespState.test_case);
    zip.file("selectors.json", apiRespState.selectors);
    zip.file("steps.ts", apiRespState.steps);

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${filename}.zip`);
    });
  };

  return (
    sessionObject ? (
      <Box
        bgcolor={"black"}
        display={"flex"}
        width={"100%"}
        height={"100%"}
        style={{
          overflowY: "scroll"
        }}
      >
        <SidebarNav />
        <Box width={"100%"} p={10}>
          <h1 className="testCaseHero">Web Automation Script Generator</h1>
          <Box mt={5}>
            <h1
              className="testCaseDesc"
              style={{
                textAlign: "left",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              Enter framework
            </h1>
            <p
              className="testCaseDesc"
              style={{
                textAlign: "left",
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Select the framework that you are working with:
            </p>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              fullWidth
              style={{ marginBottom: "20px" }}
              error={errors.frameworkType !== undefined}
            >
              <Select
                name="frameworkType"
                value={frameworkType}
                onChange={handleChange}
                style={{ color: "white" }}
                disabled={inputsDisabled}
              >
                <MenuItem value={"OSAM Framework"}>OSAM Framework</MenuItem>
                <MenuItem value={"Playwright Framework"}>
                  Playwright Framework
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <p
              className="testCaseDesc"
              style={{
                textAlign: "left",
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Select method:
            </p>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              fullWidth
              style={{ marginBottom: "20px" }}
            >
              <Select
                name="methodType"
                value={methodType}
                onChange={handleChange}
                style={{ color: "white" }}
                disabled={inputsDisabled}
              >
                <MenuItem value={"text"}>Text Uploader</MenuItem>
                <MenuItem value={"file"}>File Uploader</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <h1 className="fileUploadHero">
              Upload your Test Case or Cucumber Script here
            </h1>
            <p
              className="testCaseDesc"
              style={{
                textAlign: "left",
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              {methodType.includes("text") ? "Text Upload" : "File Upload"}
            </p>
            {methodType.includes("text") ? (
              <FormControl
                fullWidth
                style={{ marginBottom: "20px" }}
                error={errors.text !== undefined}
              >
                <TextArea
                  ref={textAreaRef}
                  value={textInput}
                  onChange={(val) => {
                    setTextInput(val);
                    setApiRespState("");
                  }}
                  disabled={inputsDisabled}
                />
                {errors.text && (
                  <FormHelperText style={{ marginTop: 10 }}>
                    {errors.text}
                  </FormHelperText>
                )}
              </FormControl>
            ) : (
              <FormControl
                fullWidth
                style={{ marginBottom: "20px" }}
                error={errors.file !== undefined}
              >
                <FileUpload
                  handleFileUpload={handleFileUpload}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  disabled={inputsDisabled}
                  fileTypes=".txt, .docx, .xml, .csv, .xlsx, .feature"
                />
                {errors.file && (
                  <FormHelperText style={{ marginTop: 10 }}>
                    {errors.file}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Box>
          <Button
            variant="contained"
            onClick={handleSubmit}
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
              border: "1px solid lightgrey",
              marginTop: "15px",
              boxShadow: "0 0 12px white",
            }}
            disabled={inputsDisabled}
          >
            {isLoading ? "Loading script..." : "Generate Script"}
          </Button>
          {apiRespState && (
            <Box>
              <h1 className="testCaseHero">Generated Automation Script</h1>
              <Box
                bgcolor={"#3c3c3e"}
                width={"100%"}
                p={2}
                borderRadius={5}
                style={{ color: "white" }}
                fontSize={16}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  padding={"0 20px"}
                >
                  <Download downloadFile={downloadFile} />
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <QueryBuilder />{" "}
                    <span style={{ display: "inline-block", paddingLeft: "5px" }}>
                      Time
                    </span>
                    <span style={{ display: "inline-block", padding: "0 5px" }}>
                      :
                    </span>
                    {formatTime(apiRespState.time_taken)}{" "}
                  </p>
                </Box>
                <div className="prettyprint">
                  <pre>
                    <h3>Steps:</h3>
                    {JSON.stringify(apiRespState.steps).replaceAll(/\\n/g, "\n")}
                  </pre>
                </div>
                <div className="prettyprint">
                  <pre>
                    <h3>Method:</h3>
                    {JSON.stringify(apiRespState.method).replaceAll(/\\n/g, "\n")}
                  </pre>
                </div>
                <div className="prettyprint">
                  <pre>
                    <h3>Selectors:</h3>
                    {JSON.stringify(apiRespState.selectors).replaceAll(
                      /\\n/g,
                      "\n"
                    )}
                  </pre>
                </div>
                <div className="prettyprint">
                  <pre>
                    <h3>Test Case:</h3>
                    {JSON.stringify(apiRespState.test_case).replaceAll(
                      /\\n/g,
                      "\n"
                    )}
                  </pre>
                </div>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    ) : <></>
  );
}
