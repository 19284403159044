import { Box, Typography, Card } from "@mui/material";
import React from "react";
import dataLogo from "../assets/images/dataLogo.png";
import space5logo from "../assets/images/space5logo.png";
import SidebarNav from "../components/Sidebar/Sidebar";
import useRedirect from "../hooks/useRedirect";
export default function Home() {
  const { tokenObject } = useRedirect();
  const sessionObject = sessionStorage.getItem("tokenObject");

  return (tokenObject && tokenObject?.accessToken) ||
    sessionObject ? (
    <Box
      bgcolor={"black"}
      display={"flex"}
      width={"100%"}
      height={"100%"}
      style={{
        overflowY:"scroll"
      }}
    >
      <SidebarNav />
      <Box width={"100%"} p={5}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <img src={space5logo} alt="dataLogo" />
          </Box>
          <Box>
            <img src={dataLogo} alt="dataLogo" />
          </Box>
        </Box>
        <Box mt={5} textAlign={"center"}>
          <h1 style={{ fontSize: "36px" }} className="heroText">
            GeniTester - ECG
          </h1>
          <Typography
            className="heroText"
            style={{ marginTop: "15px", fontSize: "15px" }}
          >
            Extract - Curate - Generate
          </Typography>
        </Box>

        <Box mt={10}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <div class="container">
              <Card class="use-case">Test Case Generator</Card>
              <Card class="use-case">Web Automation Test Script Generator</Card>
              {/* <Card class="use-case">Windows Application Testing</Card>
                <Card class="use-case">Mobile Application Testing</Card> */}
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  )  : (
    <></>
  );
}
