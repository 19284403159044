import { useCallback, useEffect, useState } from "react";

function parseURLParams(url) {
    var queryStart = url.indexOf("?") + 1,
        queryEnd = url.indexOf("#") + 1 || url.length + 1,
        query = url.slice(queryStart, queryEnd - 1),
        pairs = query.replace(/\+/g, " ").split("&"),
        parms = {},
        i,
        n,
        v,
        nv;

    if (query === url || query === "") return;

    for (i = 0; i < pairs.length; i++) {
        nv = pairs[i].split("=", 2);
        n = decodeURIComponent(nv[0]);
        v = decodeURIComponent(nv[1]);

        if (!parms.hasOwnProperty(n)) parms[n] = [];
        parms[n].push(nv.length === 2 ? v : null);
    }
    return parms;
}

function useRedirect() {
    const [tokenObject, setTokenObject] = useState(null);
    const [firstVisit, setFirstVisit] = useState(
        sessionStorage.getItem("firstVisit")
            ? sessionStorage.getItem("firstVisit")
            : "true"
    );

    useEffect(() => {
        if (
            window.location.href.includes("access_token") ||
            firstVisit === "false"
        ) {
            const urlString = window.location.href;
            const urlParams = parseURLParams(urlString);
            if (urlParams && urlParams.access_token) {
                const newTokenObject = {
                    accessToken: urlParams.access_token[0],
                    expiresIn: urlParams.expires_in
                        ? parseInt(urlParams.expires_in[0])
                        : 0,
                };
                sessionStorage.setItem("tokenObject", JSON.stringify(newTokenObject));
                setTokenObject(newTokenObject);
                setFirstVisit("false");
                sessionStorage.setItem("firstVisit", "false");

                // Empty the query parameters
                const newUrl = window.location.href.split("?")[0];
                window.history.replaceState({}, document.title, newUrl);
            } else {
                const savedToken = JSON.parse(sessionStorage.getItem("tokenObject"));
                setTokenObject(savedToken);
            }
        } else {
            console.log("Inside else")
            window.location.href = "https://genisdlc-nonprod.mobility.corpinter.net/genitester-backend/auth/";
        }
    }, [firstVisit]);

    const isTokenExpired = useCallback(() => {
        if (tokenObject && tokenObject.expiresIn) {
            const expirationTime = Date.now() / 1000 + tokenObject.expiresIn;
            const now = Math.floor(Date.now() / 1000); // Current time in seconds
            return expirationTime < now;
        }
        return false;
    }, [tokenObject]);

    useEffect(() => {
        if (!firstVisit && (isTokenExpired() || !tokenObject)) {
            window.location.href = "https://genisdlc-nonprod.mobility.corpinter.net/genitester-backend/auth/";
        }
    }, [firstVisit, tokenObject, isTokenExpired]);

    return { tokenObject, isTokenExpired };
}

export default useRedirect;
